import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const AboutUs = () => {
    return (
        <Layout>
            <Seo title="About Us" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>About Us</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/singapore1.jpg"
                                    aspectRatio={16 / 10}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <div className="mb-3">
                                        <h2>Delta Corp Asset Management</h2>
                                        <p>
                                            Delta Corp Asset Management, building on the success seen by its commercial operating arm –
                                            Delta Corp Bulk Logistics, with 50+ vessels on charter at any given time ranging from
                                            handysize to post panamax on dry and parcel tankers to suezmax on wet, has stepped into
                                            managing vessels for our own needs as well as offering third-party ship management services
                                            to other Owners as a one stop boutique solution for their occean transportation
                                            requirements.
                                        </p>

                                        <p>We offer Technical Ship Management, Crew Management, and all related services to: </p>
                                        <ul>
                                            <li>Ocean Going Vessels (with a focus on Tankers and Bulkers)</li>
                                            <li>Drill Ships and Semi Submersibles (Offshore vessels)</li>
                                            <li>Jack-Ups (Offshore vessels)</li>
                                            <li>
                                                Power Generation Plants and Mining Gensets (burning fuels or gas and fitted with marine
                                                engines)
                                            </li>
                                        </ul>
                                    </div>

                                    <h2>Ethics and Compliance</h2>
                                    <p>
                                        At Delta we make a commitment to pursue our business with integrity, respecting different
                                        cultures and the dignity and rights of individuals in all countries. We support the principles
                                        outlined in the Universal Declaration of Human Rights and commit ourselves to the observance of
                                        fundamental labour and environmental standards.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 order-2 order-lg-1 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <div className="mb-3">
                                        <h2>Our mission</h2>
                                        <p>
                                            Our mission is to become an organization that changes the dynamics of ship management by
                                            leveraging technology for the welfare of crew, protecting the asset value by providing
                                            innovative cost-effective solutions to our clients and total transparency of our
                                            operations.
                                        </p>
                                    </div>
                                    <h2>First things first</h2>
                                    <p>
                                        All the aspects of our work processes, organization and operations are oriented towards our
                                        customers’ needs for transparency.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/port1.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
